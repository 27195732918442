<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="plateNumber">
          <SuggestParking v-model="query.parkingLotId" />
          <el-input v-model="query.plateNumber" placeholder="请输入车牌号查询" prefix-icon="el-icon-search"
            class="width260 margin-right10 margin-left38" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ retData.total }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">蓝色车牌：</div>
          <div class="text">{{ retData.blue }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">黄色车牌：</div>
          <div class="text">{{ retData.yellow }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">新能源车牌：</div>
          <div class="text">{{ retData.newEnergy }}</div>
        </div>
      </div>
      <div class="content">
        <div class="content-item">
          <div class="title">其他：</div>
          <div class="text">{{ retData.other }}</div>
        </div>
      </div>
    </div>
    <div class="content-box section">
      <div>
        <!--        v-loading="isLoading"-->
        <el-table :data="tableData" border class="common-table" size="medium" :row-style="{ height: 46, padding: 0, }"
          style="width: 100%">
          <!--          width="300"-->
          <el-table-column align="center" label="车牌号码" prop="plateNumber">
            <template slot-scope="scope">
              <Numplate :type="scope.row.color">
                {{ scope.row.plateNumber }}
              </Numplate>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--              align="center"-->
          <!--              label="当前绑定手机"-->
          <!--              prop="mobile"-->
          <!--              width="300"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <!--          <el-table-column align="center" label="上次绑定手机" width="300">-->
          <!--          </el-table-column>-->
          <!--          <el-table-column align="center" label="绑定时间" prop="bindDate">-->
          <!--            <template slot-scope="scope">-->
          <!--              {{ scope.row.bindDate === 0 ? "" : scope.row.bindDate }}-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column align="center" label="是否绑定" width="80">-->
          <!--            <template slot-scope="scope">-->
          <!--              {{ scope.row.isBind === "0" ? "是" : "否" }}-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column align="center" label="是否认证" width="80">-->
          <!--            <template slot-scope="scope">-->
          <!--              {{ scope.row.isAudit === "true" ? "已认证" : "未认证" }}-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column align="center" label="车牌颜色" prop="color">
            <template slot-scope="scope">
              <span>{{ plateNumberColor[scope.row.color] }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="120" fixed="right">
            <template slot-scope="scope">
              <el-button class="common-text" size="small" type="text"
                @click="goDetail(scope.row.vehicleId)">详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-box">
          <el-pagination :current-page="pageNo" :page-size="pageSize" :page-sizes="[10, 15, 30, 50]" :total="totalSize"
            background layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog :append-to-body="true" :visible.sync="exportDialogFormVisible" class="common-dialog" title="导出选项"
      width="600px">
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input v-model="form.exportSize" placeholder="请填写导出条数"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Numplate from "@/components/Numplate";
import { getVehicleList } from "@/api/vehicle";
import { doExport } from "@/api/common";
import Permission from "@/mixins/permission";
import {
  checkToken,
} from "@/api/common";
import { MessageBox } from "element-ui";

export default {
  mixins: [Permission],
  data() {
    return {
      loading: false,
      query: {
        //查询条件
        plateNumber: "",
        userPhone: "",
        isAuthority: "",
        isAudit: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      retData: {},
      total: 0,
      totalSize: 0,
      pageSize: 15,
      pageNo: 1,
      isLoading: false,
      tableData: [],
      plateNumberColor: {
        "01": "黄底黑字",
        "02": "蓝底白字",
        "03": "渐变绿色",
        "04": "黄绿渐变",
        "06": "黑底白字",
        23: "白底黑字",
        99: "其他",

        黄色: "黄底黑字",
        蓝色: "蓝底白字",
        绿色: "渐变绿色",
        黑色: "黑底白字",
        白色: "白底黑字",
      },
    };
  },
  components: {
    Numplate,
  },
  methods: {
    //查询
    goSearch() {
      this.pageSize = 10;
      this.pageNo = 1;
      this.search();
    },
    search() {
      this.isLoading = true;
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      const plateNumber = this.query.plateNumber;
      getVehicleList(
        pageNo,
        pageSize,
        plateNumber,
      ).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          if (res.returnObject !== null) {
            this.retData = res.returnObject;
            this.tableData = res.returnObject.records;
            this.total = res.returnObject.total;
            this.totalSize = res.returnObject.total;
            this.form.exportSize = res.returnObject.total;
          } else {
            this.tableData = [];
            this.total = 0;
            this.totalSize = 0;
            this.form.exportSize = 0;
          }
        }
      });
    },
    //重置
    resetForm() {
      this.query = {
        //查询条件
        plateNumber: "",
        userPhone: "",
        isAuthority: "",
        isAudit: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      this.goSearch();
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        vehicleId: this.query.vehicleId,
        userPhone: this.query.userPhone,
        isAuthority: this.query.isAuthority,
        isAudit: this.query.isAudit,
        isExport: "1",
        exportSize: this.form.exportSize,
      };

      // window.open("http://172.16.69.129:81" + '/vehicleController/getVehicleList?pageNo=' + pageNo + "&pageSize=" + pageSize + "&vehicleId=" + vehicleId + "&userPhone=" + userPhone + "&isAuthority=" + isAuthority + "&isExport=" + isExport + "&exportSize=" + exportSize);
      doExport("/vehicleController/getVehicleList", params, "车辆管理.xlsx");
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //查看详情
    goDetail(e) {
      this.$router.push({
        path: "/user_manage/vehicle_detail",
        query: {
          id: e,
        },
      });
    },
    //页码切换
    handleSizeChange(e) {
      this.pageSize = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.search();
    },
  },
  mounted() {
    checkToken()
      .then(res => {
        console.log('checkToken', res)
        if (!res || res.code !== 30) {
          MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
            confirmButtonText: '重新登录',
            callback: () => {
              window.localStorage.clear()
              window.$vue.$router.push('/login')
            }
          });
        } else {
          this.goSearch();
        }
      })
  },
};
</script>

<style lang="less" scoped></style>
